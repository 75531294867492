<template>
  <div class="content">
    <div class="background">
      <div class="row">
        <div class="col">
          <h4>Automations Documentation</h4>
        </div>
        <div class="col-auto">
          <router-link :to="{ name: 'automations' }" class="mb-4 vave-btn">BACK TO AUTOMATIONS</router-link>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h4>Overview</h4>
          <div>
            Automations will take all the models specified (Eg Users, Orders, Carts), loop through all of them, check the specified conditions and execute the selected actions only if ALL the conditions are satisfied.
            <br />This process can be very resource intensive if you have a lot of models, and the number will only increase with time.
            To prevent this problem, we can exclude models that have not been updated for a certain amount time.
            <div class="mt-2">
              The option
              <span class="badge bg-secondary">Ignore models older than</span> will exclude any model whose field updated_at as not been updated for a specified amount of time. Try to keep this to the lowest value possible.
            </div>
            <div class="mt-2">
              The option
              <span class="badge bg-secondary">Run only once per Model</span> will remember which automation has run for every model and will skip the execution if it has run alreday. This option is useful for models like Orders, that are created every time a new order is placed, unlike Carts, which are emptied and reused, and Users, that are never deleted.
            </div>
            <div class="mt-2">
              The option
              <span class="badge bg-secondary">Run every</span> will define how often the automation should run. Bear in mind that running an automation every minute for a lot of models will be very resource intensive.
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="documentationReady">
        <div class="col">
          <h4 class="mt-5">Conditions</h4>
          <div v-for="conditions,k in documentation" :key="k" class="row">
            <div class="col">
              <div class="mt-2">
                <h5>{{k}}</h5>
                <table class="table table-bordered align-middle">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Trigger</th>
                      <th>Description</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="condition,k in conditions" :key="k">
                      <td width="250px" :rowspan="condition.rowspan" v-if="condition.rowspan">{{condition.label}}</td>
                      <td width="350px">{{condition.trigger}}</td>
                      <td width="600px" v-html="condition.description" :rowspan="condition.rowspan" v-if="condition.rowspan"></td>
                      <td width="300px" :rowspan="condition.rowspan" v-if="condition.rowspan">
                        <!-- {{condition.operators}} -->
                        <div v-if="condition.operators.input.type == 'select'">
                          <ul>
                            <li v-for="option,optionKey in condition.operators.input.options" :key="optionKey">{{option}}</li>
                          </ul>
                        </div>
                        <div v-else-if="condition.operators.input.type == 'number'">
                          Number
                          {{condition.operators.input.placeholder}}
                        </div>
                        <div v-else-if="condition.operators.input.type == 'date'">Date</div>
                        <div v-else>{{condition.operators.input}}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="row">
        <div class="col">
          <div class="spinner-border spinner-border-sm ms-2" role="status"></div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h4>Actions</h4>
        </div>
      </div>
      <div class="row">
        <div class="col">
          Available actions are:
          <ul>
            <li>
              <span class="badge bg-secondary">Send email</span> to a customer or customer service.
              When sending the email to the customer, the recipient will be selected based on the model that runs the automation.
            </li>
            <li>
              <span class="badge bg-secondary">Log</span> an entry to Sentry.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "./../http.js";
import { formatValue } from "./../formatters";
import { forEach } from "lodash";

export default {
  data() {
    return {
      documentationReady: false,
      documentation: {},
    };
  },
  mounted() {
    this.getTriggers();
  },
  methods: {
    formatValue,
    getTriggers() {
      this.documentationReady = false;

      axios.get("/api/admin/automations/triggers").then((r) => {
        if (r.status == 200) {
          this.buildDocumentation(r.data);
        }
        this.documentationReady = true;
      });
    },
    buildDocumentation(data) {
      data.forEach((trigger) => {
        this.documentation[trigger.model] = [];
      });
      data.forEach((trigger) => {
        trigger.operators.forEach((operator) => {
          this.documentation[trigger.model].push({
            label: trigger.label,
            trigger: operator.label,
            description: trigger.description,
            operators: operator,
            count: trigger.operators.length,
          });
        });
      });

      // set the rowspan
      forEach(this.documentation, (triggers) => {
        triggers.forEach((trigger, k) => {
          trigger.rowspan = null;
          if (
            triggers[k - 1] == undefined ||
            triggers[k - 1].label !== trigger.label
          ) {
            trigger.rowspan = trigger.count;
          }
        });
      });
      forEach(this.documentation, (triggers) => {
        triggers.forEach((trigger) => {
          if (trigger.rowspan == null) {
            trigger.label = null;
          }
        });
      });

      console.log(this.documentation);
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  padding: 20px;
}
</style>