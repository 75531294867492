var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"mb-4 vave-btn",attrs:{"to":{ name: 'automations' }}},[_vm._v("BACK TO AUTOMATIONS")])],1)]),_vm._m(1),(_vm.documentationReady)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',{staticClass:"mt-5"},[_vm._v("Conditions")]),_vm._l((_vm.documentation),function(conditions,k){return _c('div',{key:k,staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"mt-2"},[_c('h5',[_vm._v(_vm._s(k))]),_c('table',{staticClass:"table table-bordered align-middle"},[_vm._m(2,true),_c('tbody',_vm._l((conditions),function(condition,k){return _c('tr',{key:k},[(condition.rowspan)?_c('td',{attrs:{"width":"250px","rowspan":condition.rowspan}},[_vm._v(_vm._s(condition.label))]):_vm._e(),_c('td',{attrs:{"width":"350px"}},[_vm._v(_vm._s(condition.trigger))]),(condition.rowspan)?_c('td',{attrs:{"width":"600px","rowspan":condition.rowspan},domProps:{"innerHTML":_vm._s(condition.description)}}):_vm._e(),(condition.rowspan)?_c('td',{attrs:{"width":"300px","rowspan":condition.rowspan}},[(condition.operators.input.type == 'select')?_c('div',[_c('ul',_vm._l((condition.operators.input.options),function(option,optionKey){return _c('li',{key:optionKey},[_vm._v(_vm._s(option))])}),0)]):(condition.operators.input.type == 'number')?_c('div',[_vm._v(" Number "+_vm._s(condition.operators.input.placeholder)+" ")]):(condition.operators.input.type == 'date')?_c('div',[_vm._v("Date")]):_c('div',[_vm._v(_vm._s(condition.operators.input))])]):_vm._e()])}),0)])])])])})],2)]):_c('div',{staticClass:"row"},[_vm._m(3)]),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('h4',[_vm._v("Automations Documentation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h4',[_vm._v("Overview")]),_c('div',[_vm._v(" Automations will take all the models specified (Eg Users, Orders, Carts), loop through all of them, check the specified conditions and execute the selected actions only if ALL the conditions are satisfied. "),_c('br'),_vm._v("This process can be very resource intensive if you have a lot of models, and the number will only increase with time. To prevent this problem, we can exclude models that have not been updated for a certain amount time. "),_c('div',{staticClass:"mt-2"},[_vm._v(" The option "),_c('span',{staticClass:"badge bg-secondary"},[_vm._v("Ignore models older than")]),_vm._v(" will exclude any model whose field updated_at as not been updated for a specified amount of time. Try to keep this to the lowest value possible. ")]),_c('div',{staticClass:"mt-2"},[_vm._v(" The option "),_c('span',{staticClass:"badge bg-secondary"},[_vm._v("Run only once per Model")]),_vm._v(" will remember which automation has run for every model and will skip the execution if it has run alreday. This option is useful for models like Orders, that are created every time a new order is placed, unlike Carts, which are emptied and reused, and Users, that are never deleted. ")]),_c('div',{staticClass:"mt-2"},[_vm._v(" The option "),_c('span',{staticClass:"badge bg-secondary"},[_vm._v("Run every")]),_vm._v(" will define how often the automation should run. Bear in mind that running an automation every minute for a lot of models will be very resource intensive. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Trigger")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Value")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('div',{staticClass:"spinner-border spinner-border-sm ms-2",attrs:{"role":"status"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col"},[_c('h4',[_vm._v("Actions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v(" Available actions are: "),_c('ul',[_c('li',[_c('span',{staticClass:"badge bg-secondary"},[_vm._v("Send email")]),_vm._v(" to a customer or customer service. When sending the email to the customer, the recipient will be selected based on the model that runs the automation. ")]),_c('li',[_c('span',{staticClass:"badge bg-secondary"},[_vm._v("Log")]),_vm._v(" an entry to Sentry. ")])])])])
}]

export { render, staticRenderFns }